.dropdown {
  position: relative;
  width: fit-content;
  z-index: 9999;
}

.dropdownContainer {
  width: 180px;
  display: none;
  /* position: absolute; */

  top: 100%;
  transition: all 1s ease-out;
  padding-top: 8px;
}

.dropdownContent {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  gap: 8px;
  padding-bottom: 10px;
}

.dropdownContainer button {
  cursor: pointer;
  width: 180px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px;
  color: rgba(var(--primary), 1);
  /* background-color: rgba(var(--white), 1); */
  border: 1px solid transparent;
  white-space: nowrap;
  user-select: none;
  background-color: rgba(var(--white));
  transition: all 0.3s ease-in-out;
  box-shadow: 0px 2px 1px 1px rgba(0, 0, 0, 0.11);
  border-radius: 4px;
}

.dropdownContainer button > div {
  width: 32px;
  height: 32px;

  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(var(--dark-gray), 0.05);
  border-radius: 8px;
  padding: 4px;

  margin-right: 8px;
}

.dropdownContainer button:disabled {
  opacity: 0.4;
  cursor: default;
}

.dropdownContainer button p {
  margin-left: 4px;
  text-transform: capitalize;
  flex: 1;
  text-align: left;
  line-height: 1;

  font-weight: 700;
  color: rgba(var(--primary), 1);
}

.dropdownContainer button:hover:enabled {
  cursor: pointer;
  border: 1px solid rgba(var(--secondary), 1);
}

.focus {
  display: flex;
}
.dropdownTitle {
  cursor: pointer;
  user-select: none;
  padding: 12px 24px;
  background-color: rgba(var(--white), 1);
  border: 1px solid rgba(var(--secondary), 1);
  border-radius: 4px;
  color: var(--color-default);
  font-weight: 700;
  width: 180px;
  text-align: center;
}

.noWallets {
  color: var(--color-default);
  font-size: 12px;
  font-style: italic;
  user-select: none;
}

.connected {
  width: 8px;
  height: 8px;
  background-color: var(--green);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.connected > div {
  width: 4px;
  height: 4px;
  background-color: var(--dark-green);
  border-radius: 50%;
}
