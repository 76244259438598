.wrapper {
    position: relative;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    background-image: url("/background/bgk-eagle.png"); 
    background-color: #cccccc;
    background-position: center; 
    background-repeat: no-repeat;
    background-size: cover;
}

.wrapper h3 {
    font-size: 18px;
    margin-bottom: 8px;
    letter-spacing: 2px;
}

.wrapper h4 {
    font-weight: 400;
    color: rgba(var(--primary), 0.7);
    margin-bottom: 32px;
}